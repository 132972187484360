import React, { useEffect, useState } from 'react';
import { navigate } from 'gatsby';
import Layout from '../../../../components/layout'
import Seo from '../../../../components/seo';
import Origin from '../../../../utils/origin';
import './styles/main.scss';
import '../../../../../static/styles/elements/recaptcha.scss';

const Page = (location) => {
  /* eslint-disable-next-line no-unused-vars */
  const [origin, setOrigin] = useState();

  const goToWhatsApp = event => {
    navigate('/conta/login/');
  };

  const trackAnalytics = () => {
  };

  useEffect(() => {
    setOrigin(Origin.get());
    trackAnalytics();
  }, [])

  return (
    <Layout page="workshop-success" location={location} name="workshop">
      <Seo
        title={`Workshop 25/10`}
        description="Workshop 25/10."
        url="/workshop/sucesso/" />

      <section className={'workshop'}>
        <div className={'container'}>
          <div className="row register">
            <div className="col-xs-12">
              <h1 className={'title'}>Quase lá!</h1>
              <h2>Acesse agora o grupo exclusivo do WhatsApp para confirmar a sua inscrição.</h2>
              <p>Lá, você vai ter acesso as informações do evento em primeira mão.</p>
              <button type="button" className="button button-primary" onClick={goToWhatsApp}>Acessar o grupo no WhatsApp</button>
            </div>
          </div>
        </div>
      </section>
    </Layout>
  );
};

export default Page;
